@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.about-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 11%;
  margin-bottom: 15%;
}

.about-img-div {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 45%;
}

.about-img {
  height: 230px;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.about-text-div {
  width: 70%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}
.about-text-name-div {
  width: 30%;
}

.about-text-name-1 {
  font-family: 'Reenie Beanie', cursive;
  font-size: 50px;
  /* color: #1b8797; */
  color: black;
  margin: 5% 0 0 0;
  font-weight: 200;
}

.about-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 15px;
}



#contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#name-input {
  -webkit-appearance: none;
  box-shadow: 0px 0px 6px 2px #1b869738;
  -webkit-box-shadow: 0px 0px 6px 2px #1b869738;
  -moz-box-shadow: 0px 0px 6px 2px #1b869738;
  border: 0px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#email-input {
  -webkit-appearance: none;
  box-shadow: 0px 0px 6px 2px #1b869738;
  -webkit-box-shadow: 0px 0px 6px 2px #1b869738;
  -moz-box-shadow: 0px 0px 6px 2px #1b869738;
  border: 0px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#subject-input {
  -webkit-appearance: none;
  box-shadow: 0px 0px 6px 2px #1b869738;
  -webkit-box-shadow: 0px 0px 6px 2px #1b869738;
  -moz-box-shadow: 0px 0px 6px 2px #1b869738;
  border: 0px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#message-input {
  -webkit-appearance: none;
  box-shadow: 0px 0px 6px 2px #1b869738;
  -webkit-box-shadow: 0px 0px 6px 2px #1b869738;
  -moz-box-shadow: 0px 0px 6px 2px #1b869738;
  border: 0px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

/* #submit-button {
  -webkit-appearance: none;
  background-color:  #70d1bd;  
  color: rgb(234,92,90); 
  font-size: 20px;
  font-weight: 600;
  font-family: Cutive Mono, monospace;
  margin-top: 3%;
  box-shadow: 6px 6px 0px -1px rgb(234,92,90);
  -webkit-box-shadow: 6px 6px 0px -1px rgb(234,92,90);
  -moz-box-shadow: 6px 6px 0px -1px rgb(234,92,90);
  transition: 400ms;
} */

#submit-button  {
  border-radius: 40px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  font-family: 'Reenie Beanie', cursive;
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 28px;
  /* border: 1px solid black; */
  outline: none;
  color: black;
  font-weight: 400;
  padding-top: 5px;
  box-shadow: 0px 0px 10px 1px #1b8697a4;
  -webkit-box-shadow: 0px 0px 10px 1px #1b8697a4;
  -moz-box-shadow: 0px 0px 10px 1px #1b8697a4;
  /* color:#1b8797; */

  -webkit-transition: 200ms;

  transition: 200ms;
  margin-top: 5%;
}
  
#submit-button:hover {
  -webkit-transition: 200ms;
  transition: 200ms;
  box-shadow: 0px 0px 0px 0px #ea5c5a;
  -webkit-box-shadow: 0px 0px 0px 0px #ea5c5a;
  -moz-box-shadow: 0px 0px 0px 0px #ea5c5a;
  border: 1px solid #1b869738;
}
/* 
#submit-button:hover {
  -webkit-appearance: none;
  box-shadow: -6px -6px 0px -1px rgb(234,92,90);
  -webkit-box-shadow: -6px -6px 0px -1px rgb(234,92,90);
  -moz-box-shadow: -6px -6px 0px -1px rgb(234,92,90);
  transition: 400ms;
  background-color:  #70d1bd;  
} */
/* 
#submit-button:focus {
  outline: 0;
} */
#message-input:focus {
  outline: 0;
}
#subject-input:focus {
  outline: 0;
}
#email-input:focus {
  outline: 0;
}
#name-input:focus {
  outline: 0;
}


.contact-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 5%;
}

#contact-page {
  margin-top: 7%;
}

.contact-title {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Reenie Beanie', cursive;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 6px;
  margin-bottom: 2%;
  /* color: #ea5c5a; */
  /* color:#1b8797 */
  color: black;
}

.line {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}



.contact-content-container {
  margin: 0 auto;
  width: 32%;
  padding-top: .5%;
  border-radius: 300px;
  margin-bottom: 3%;
}

.phone {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 200;
  line-height: 42px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.email {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 200;
  line-height: 42px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.address {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 200;
  line-height: 42px;
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 10%;
}

.social-pics-container {
  width: 30%;
  padding-bottom: 1%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.social-links-container1 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.social-links-container2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.social-links-container3 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.social-link1 {
  margin-left: 20%;
  margin-right: 20%;
}

.social-link2 {
  margin-left: 20%;
  margin-right: 20%;
}

.social-link3 {
  margin-left: 20%;
  margin-right: 20%;
}

.social-image1 {
  height: 110%;
}
.social-image2 {
  height: 90% !important;
  -webkit-transition: -webkit-transform .8s ease-in-out;
  transition:         -webkit-transform .8s ease-in-out;
  transition:         transform .8s ease-in-out;
  transition:         transform .8s ease-in-out, -webkit-transform .8s ease-in-out;
}

.social-image3 {
  height: 110%;
}


.social-image1:hover {
  -webkit-animation: swing .75s ease;
  animation: swing .75s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.social-image2:hover
{
  /* -webkit-animation: swing .75s ease;
  animation: swing .75s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1; */

  -webkit-transform: rotate(720deg);
	transform: rotate(720deg);
}



.social-image3:hover
{
  -webkit-animation: swing .75s ease;
  animation: swing .75s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
} 

@keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
       transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
       transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.contact-main-container {
  margin-top: 1%;
  height: 650px;
}

.contact-form-container {
  width: 30%;
  margin: 0 auto;
}

/* .contact-title {
  color: #71d1bd;
  font-family: 'Cutive Mono', monospace;
  font-size: 30px;
  border-bottom: 2px solid #71d1bd;
  width: 13%;
  margin: 0 auto;
  text-align: center;
  font-weight: 800;
} */

.contact-description {
  color: black;
  font-family: 'Cutive Mono', monospace;
  font-size: 20px;
  text-align: center;
  margin-bottom: 2%;
}

.social-container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  padding-top: 1%;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
}
.social-icons .li-social {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}
.social-icons i {
  color: #fff;
  position: absolute;
  top: 15px;
  left: 18px;
  -webkit-transition: all 265ms ease-out;
  transition: all 265ms ease-out;
}
.social-icons .a-social {
  display: inline-block;
}
.social-icons .a-social:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, rgb(0, 237, 245), #70d1bd );
  -webkit-transition: all 265ms ease-out;
  transition: all 265ms ease-out;
}
.social-icons .a-social:hover:before {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 265ms ease-in;
  transition: all 265ms ease-in;
}
.social-icons .a-social:hover i {
  transform: scale(2.1);
  -ms-transform: scale(2.1);
  -webkit-transform: scale(2.1);
  color: #70d1bd;
  background: -webkit-linear-gradient(45deg, #2dc7ff, #70d1bd );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 265ms ease-in;
  transition: all 265ms ease-in;
}
.fa {
  width: 44%;
  padding-top: 1px;
}

.li-social {
  padding: 0 2% 0 2%;
}







.color-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: -2% 4%;
}

.color-list-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 0;
}

.color-list-item-1 {
  list-style-type: none;
  margin: 5% 5% 1% 5%;
  padding: 0;
}

.color-list-item {
  list-style-type: none;
  margin: 10%;
  padding: 0;
}
.color-img {
  height: 500px;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.38);
}

.img-desc-text {
  font-family: 'Neucha', cursive;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 5%;
  text-align: right;
  color: black;
}



.monochrome-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: -2% 5%;
}

.monochrome-list-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 0;
}

.monochrome-list-item-1 {
  list-style-type: none;
  margin: 5%;
  padding: 0;
}

.monochrome-list-item {
  list-style-type: none;
  margin: 10%;
  padding: 0;
}
.monochrome-img {
  height: 500px;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.38);
}

.monochrome-img-beach {
  height: 320px;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.38);
}

#scroll-your-role-id {
  background-color: #ffffff00;
  font-family: 'Reenie Beanie', cursive;
  border-radius: 100%; 
  width: 50px;
  padding: 12px 12px 12px 12px;
  font-size: 30px;
  font-weight: 400;
  border: 0px solid black;
}

#scroll-your-role-id:hover {
  background-color: #ffffff00;
  font-family: 'Reenie Beanie', cursive;
  border-radius: 100%; 
  width: 50px;
  padding: 12px 12px 12px 12px;
  font-size: 30px;
  font-weight: 400;
  color: #ec413e;
  border: 0px solid black;
}

#scroll-your-role:hover {
  background-color: #ffffff00;
  font-family: 'Reenie Beanie', cursive;
  border-radius: 100%; 
  width: 50px;
  padding: 12px 12px 12px 12px;
  font-size: 30px;
  font-weight: 400;
  color: #ec413e;
  border: 0px solid black;
}

.mainpage-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 2%;
}

.mainpage-cover-img {
  height: 500px;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.38);
}

.mainpage-cover-img-text {
  position: absolute;
  top: 430px;
  left: 30%;
  font-family: 'Reenie Beanie', cursive;
  font-size: 50px;
  font-weight: 200;
  /* transform: rotate(-9deg); */
  color: white;
  opacity: 100%;
}


.nav-container {
  background-color: rgba(255, 255, 255, 0);
  width: 85%;
  margin: 0 auto;
  border-color: #8795a1;
  text-decoration: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
	/* border-bottom: 1px solid black; */
	margin-top: 2%;
}

.nav-bullets {
  list-style: none;
  width: 40%;
  margin: 1% 0% 1% 0%;
  display: flex;
	align-content: center;
	justify-content: space-around;
	padding: 0;
}
.links {
  display: inline;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  list-style: none;
  margin-right: 5%;
	margin-left: 5%;
}
.nav-text-links {
  text-decoration: none;
  /* color: black; */
  font-size: 19px;
	font-family: 'Poiret One', cursive;
	font-weight: 600;
	color: #1b8798;
}

.link-text {
	-webkit-transition: background-size .4s ease;
	transition: background-size .4s ease;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(52%, transparent), color-stop(0, rgba(255, 251, 0, 0.575))) center center/0% 75% no-repeat;
  background: linear-gradient(to bottom, transparent 52%, rgba(255, 251, 0, 0.575) 0) center center/0% 75% no-repeat;
	cursor: pointer;
	padding-top: 0px;
  padding-left: 15px;
	padding-right: 15px;
}
.link-text:hover {
  background-size: 99% 47%;
}
.link-text:active {
  background-size: 10% 50%;
}





/* 
.nav-title-span-1 {

}
.nav-title-span-2 {
	
} */
#my-name {
	margin: 0 auto;
	width: 100%;
	
}
.title {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 50%;
}
.name-text {
	font-size: 40px;
  text-decoration: none !important;
	color: black;
	/* font-family: 'Amatic SC', cursive; */
  font-family: 'Reenie Beanie', cursive;
	/* color: #f53c03; */
	color: #ec413e;

	letter-spacing: 1px;
	font-weight: 200;
	text-align: center;
	width: 100%;
	margin: 1% 0 0 0;
	-webkit-transform: scale(1, .8);
	        transform: scale(1, .8);
	/* display: flex;
	flex-direction: column;
	justify-content: space-around; */
}
#link-no-style {
	text-decoration: none !important;
}
.nav-name {
  border-width: 1px 0;
  /* padding-right: 5%; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
}






/*.nav-text-links:hover {
  text-decoration: underline;
  color: black;
}*/
/* 
strong {
  font-family: "Sarina", cursive;
  font-size: 35px;
  /*font-family: "Chango", cursive;*/
  /*  font-family: 'Gravitas One', cursive;
} */




/* other prefixed animation blocks ommitted for brevity */

h4 {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  color: black;
  text-align: center;
}

/* .sm-link {
  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  --uismLinkTextColor: var(--smLinkTextColor);
  --uismLinkTextColorHover: var(--smLinkTextColorHover);

  display: var(--uismLinkDisplay);
  color: var(--uismLinkTextColor);
  position: relative;
  overflow: hidden;
}

a.sm-link {
  text-decoration: none;
}
 */





.sm-link__label {
	display: block;
	
}


/* sm-link_padding-all */

/* .sm-link_padding-all {
  --uismLinkLineWeight: var(--smLinkLineWeight, 2px);
  --uismLinkLineColor: var(--smLinkLineColor, #000);
  --uismLinkPadding: var(--smLinkPadding, 5px);

  padding: var(--uismLinkPadding);
}

.sm-link_padding-all::before,
.sm-link_padding-all::after {
  width: 100%;
  height: var(--uismLinkLineWeight);
  left: 0;
}

.sm-link_padding-all::before {
  top: 0;
}

.sm-link_padding-all::after {
  bottom: 0;
}

.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after {
  width: var(--uismLinkLineWeight);
  height: 100%;
  top: 0;
}

.sm-link_padding-all .sm-link__label::before {
  left: 0;
}

.sm-link_padding-all .sm-link__label::after {
  right: 0;
}

.sm-link_padding-all::before,
.sm-link_padding-all::after,
.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after {
  content: "";
  background-color: var(--uismLinkLineColor);
  position: absolute;
  opacity: 0;

  will-change: transform, opacity;
  transition-property: transform, opacity;
}

.sm-link_padding-all:hover::before,
.sm-link_padding-all:hover::after,
.sm-link_padding-all:hover .sm-link__label::before,
.sm-link_padding-all:hover .sm-link__label::after {
  opacity: 1;
} */

/* sm-link_padding-bottom */
/* 
.sm-link_padding-bottom {
  --uismLinkLineWeight: var(--smLinkLineWeight, 2px);
  --uismLinkLineColor: var(--smLinkLineColor, #000);

  padding-bottom: var(--uismLinkLineWeight);
  position: relative;
}

.sm-link_padding-bottom::after {
  content: "";
  width: 100%;
  height: var(--uismLinkLineWeight);
  background-color: var(--uismLinkLineColor);

  position: absolute;
  left: 0;
  bottom: 0;
} */

/* sm-link_bg */
/* 
.sm-link_bg {
  --uismLinkLineColor: var(--smLinkLineColor, #000);
  --uismLinkTextColorHover: var(--smLinkTextColorHover, #fff);
  --uismLinkPadding: var(--smLinkPadding, 5px);

  padding: var(--uismLinkPadding);
  transition: color 0.3s ease-out;
}

.sm-link_bg::before,
.sm-link_bg::after {
  content: "";
  background-color: var(--uismLinkLineColor);
  opacity: 0;
  position: absolute;

  transition: transform 0.2s ease-out, opacity 0.2s ease-out 0.03s;
}

.sm-link_bg .sm-link__label {
  position: relative;
  z-index: 2;
}

.sm-link_bg:hover::before,
.sm-link_bg:hover::after {
  opacity: 1;
  transition-duration: 0.35s, 0.35s;
  transition-delay: 0s, 0s;
}

.sm-link_bg:hover {
  color: var(--uismLinkTextColorHover);
} */

/* sm-link_text */

.sm-link_text::before {
  content: attr(data-sm-link-text);
  color: var(--uismLinkTextColorHover);
  position: absolute;
}

.sm-link_text::before,
.sm-link_text .sm-link__label {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0.6, 0.08, 1.01);
          transition-timing-function: cubic-bezier(0.86, 0.6, 0.08, 1.01);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.sm-link_text:hover::before,
.sm-link_text:hover .sm-link__label {
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
}


.sm-link6::before,
.sm-link6::after, 
.sm-link6 .sm-link__label::before,
.sm-link6 .sm-link__label::after{
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.sm-link6::before, 
.sm-link6::after{
  width: 100%;
  height: var(--uismLinkLineWeight);
  left: 0;
}

.sm-link6 .sm-link__label::before,
.sm-link6 .sm-link__label::after{
  width: var(--uismLinkLineWeight);
  height: 100%;
  top: 0;
}

.sm-link6::before{
  top: 0;
  -webkit-transform: translate3d(-105%, 0, 0);
          transform: translate3d(-105%, 0, 0);
}

.sm-link6::after{
  bottom: 0;
  -webkit-transform: translate3d(105%, 0, 0);
          transform: translate3d(105%, 0, 0);
}

.sm-link6 .sm-link__label::before{
  left: 0;
  -webkit-transform: translate3d(0, 105%, 0);
          transform: translate3d(0, 105%, 0);
}

.sm-link6 .sm-link__label::after{
  right: 0;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0);
}

.sm-link6:hover::before,
.sm-link6:hover::after,
.sm-link6:hover .sm-link__label::before,
.sm-link6:hover .sm-link__label::after{
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.sm-link6:hover::before{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.sm-link6 .sm-link__label::after,
.sm-link6:hover::after{
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
}

.sm-link6::after,
.sm-link6:hover .sm-link__label::after{
  -webkit-transition-delay: .15s;
          transition-delay: .15s;
}

.sm-link6::before,
.sm-link6:hover .sm-link__label::before{
  -webkit-transition-delay: .35s;
          transition-delay: .35s;
}

html {
  scroll-behavior: smooth;
}
